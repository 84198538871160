import React from 'react'
import AppHeaderDocuments from '~/js/components/AppHeaderDocuments'
import { Document, DocumentsList } from '~/js/components/DocumentsList'
import Modal from '~/js/components/Modal'
import ModalRemove from '~/js/components/ModalRemove'
import ModalShare from '~/js/components/ModalShare'
import Pagination from '~/js/components/Pagination'
import { Tab, TabsList } from '~/js/components/TabsList'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import ControlButtonsDocument from '~/js/components/ControlButtonsDocument'
import StatusMessages from '~/js/containers/StatusMessages'
import {
  DOCUMENT_CAT_ALL,
  DOCUMENT_CAT_MINE,
  DOCUMENT_CAT_WAITING_ME,
  DOCUMENT_CAT_WAITING_OTHERS,
  DOCUMENT_CATS,
  DOCUMENT_FORMAT_PDF,
  DOCUMENT_STATUS_SAVED,
  DOCUMENT_STATUS_SIGNED,
  DOCUMENT_STATUS_SIGNED_PARTIALLY,
  DOCUMENT_UPLOAD_TYPE_UPLOAD,
  DOCUMENT_CAT_ALL_COMPANY
} from '~/js/models/Document'
import * as documentService from '~/js/services/user/document'
import * as userService from '~/js/services/user/user'
import * as workspaceService from '~/js/services/user/workspace'
import emptySearchImg from '~/img/illustration/empty-search.svg'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath, getUserWorkspace } from '~/js/routes/router'
import Select from '~/js/components/Select'
import makeAnimated from 'react-select/animated'
import ModalDocumentMigrationCompany from '../../../../components/ModalDocumentMigrationCompany'
import DropzoneComponent from '~/js/components/DropzoneComponent'
import ModalFutureDiscount from '../../../../components/ModalFutureDiscount'
import apiClient from '~/js/utils/apiClientDocs'
import PropTypes from 'prop-types'
import Loader from '~/js/components/Loader'
import { Link } from 'react-router-dom'
import ErrorIcon from '~/img/icons/error-logo.svg'
import InformationBar from '~/js/components/InformationBar'
import ModalGenerateLink from '~/js/components/ModalGenerateLink'
import DropUpList from '../../../../components/DropUpList'

let documentStatusOptions = []
let categoriesOptions = []

const animatedComponents = makeAnimated()

export default class Documents extends React.Component {
  constructor(props) {
    super(props)

    const { paginationHistory } = props
    const queryString = this.parseQueryString()
    const page = paginationHistory.page || queryString.page || 1
    const pageSize = paginationHistory.pageSize || 10
    const filterHistory = this.setDocumentFilterHistory()

    this.state = {
      tabs: this.getTabs(),
      documentsLoading: true,
      documents: [],
      user: props.user,
      notViewedDocuments: [],
      pagination: {
        page: page,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        total: undefined,
      },
      selectAllChecked: this.props.selectAllHistory,
      isSignaturesCountOk: true,
      canSign: false,
      modalRemove: {
        active: false,
        documentIndex: undefined,
      },
      modalShare: {
        active: false,
        document: undefined,
        documentIndex: undefined,
      },
      modalGenerateLinkActive: false,
      searchValue: this.props.searchFieldHistory,
      documentStatus: filterHistory.documentStatus,
      documentCategory: filterHistory.documentCategory,
      selectedFilter: filterHistory.selectedFilter,
      documentCategories: [],
      modalMigrateDocument: {
        active: false,
      },
      discountFirst: !!new URL(location.href).searchParams.get('discount25'),
      discountSecond: !!new URL(location.href).searchParams.get('discount40'),
      modalFutureDiscount: {
        active: !!new URL(location.href).searchParams.get('discount25') ||
          !!new URL(location.href).searchParams.get('discount40'),
      },
      selectedAllCanBeSigned: true,
      signingLink: '',
      expireAfter: '',
      signingLinkError: '',
      canGenerateLink: false,
    }

    this.fetchCategories()
    this.showSelectedValue = this.showSelectedValue.bind(this)
    this.onUploadSuccess = this.onUploadSuccess.bind(this)
    this.onDocumentsTabClick = this.onDocumentsTabClick.bind(this)
    this.onPaginationNextClick = this.onPaginationNextClick.bind(this)
    this.onPaginationPreviousClick = this.onPaginationPreviousClick.bind(this)
    this.onDocumentSelect = this.onDocumentSelect.bind(this)
    this.onDocumentDownloadClick = this.onDocumentDownloadClick.bind(this)
    this.onDocumentsDownloadClick = this.onDocumentsDownloadClick.bind(this)
    this.onDocumentsDeleteClick = this.onDocumentsDeleteClick.bind(this)
    this.onModalRemoveDocumentsClick = this.onModalRemoveDocumentsClick.bind(this)
    this.onDocumentsMigrateClick = this.onDocumentsMigrateClick.bind(this)
    this.onModalMigrateDocumentsClick = this.onModalMigrateDocumentsClick.bind(this)
    this.onDocumentShareClick = this.onDocumentShareClick.bind(this)
    this.hideModalShare = this.hideModalShare.bind(this)
    this.inviteSigner = this.inviteSigner.bind(this)
    this.inviteSigners = this.inviteSigners.bind(this)
    this.onDocumentDeleteClick = this.onDocumentDeleteClick.bind(this)
    this.onModalRemoveDocumentClick = this.onModalRemoveDocumentClick.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onFiltersSelectChange = this.onFiltersSelectChange.bind(this)
    this.onModalMigrateDocumentsToWorkspaceClick = this.onModalMigrateDocumentsToWorkspaceClick.bind(this)
    this.acceptDiscount = this.acceptDiscount.bind(this)
    this.onModalFutureDiscountCloseClick = this.onModalFutureDiscountCloseClick.bind(this)
    this.onSelectAllChange = this.onSelectAllChange.bind(this)
    this.onUploadSuccess = this.onUploadSuccess.bind(this)
    this.parseQueryString = this.parseQueryString.bind(this)
    this.resetControlButtons = this.resetControlButtons.bind(this)
    this.setCompanies = this.setCompanies.bind(this)
    this.setDocuments = this.setDocuments.bind(this)
    this.setPaginationPage = this.setPaginationPage.bind(this)
    this.transformFetchedDocuments = this.transformFetchedDocuments.bind(this)
    this.updateOptions = this.updateOptions.bind(this)
    this.onDocumentsSign = this.onDocumentsSign.bind(this)
    this.selectDeselectAll = this.selectDeselectAll.bind(this)
    this.documentSelectEffect = this.documentSelectEffect.bind(this)
    this.handleDocumentSelectHistory = this.handleDocumentSelectHistory.bind(this)
    this.resetSelectedAllDocuments = this.resetSelectedAllDocuments.bind(this)
    this.setTabListHistory = this.setTabListHistory.bind(this)
    this.setDocumentFilterHistory = this.setDocumentFilterHistory.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.getOffset = this.getOffset.bind(this)
    this.updateSelectedDocumentHistory = this.updateSelectedDocumentHistory.bind(this)
    this.resolveCanSign = this.resolveCanSign.bind(this)
    this.onGenerateSigningLink = this.onGenerateSigningLink.bind(this)

    this.fetchCompanies()
  }

  fetchCategories() {
    documentService
      .getAllDocumentCategories()
      .then(data => this.setCategories(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setCategories(data) {
    const { data: categories } = data

    this.setState({
      documentCategories: categories
    })
  }

  fetchCompanies() {
    workspaceService
      .getWorkspaces(this.props.user)
      .then(data => this.setCompanies(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setCompanies(data) {
    const { data: companies } = data

    this.setState({
      companies,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { props, state } = this

    this.state.documentStatus ?
      documentStatusOptions.forEach(statusOption => statusOption.disabled = true) :
      documentStatusOptions.forEach(statusOption => statusOption.disabled = false)

    if (prevProps.user.workspaceId !== props.user.workspaceId) {
      this.setState({ tabs: this.getTabs() })
    }

    if (state.documentStatus !== prevState.documentStatus
      || state.documentCategory !== prevState.documentCategory
      || state.pagination.page !== prevState.pagination.page
      || state.pagination.limit !== prevState.pagination.limit
      || this.getActiveTab(state.tabs).id !== this.getActiveTab(prevState.tabs).id
    ) {
      this.props.saveDocumentPaginationHistoryInfo(state.pagination.page, state.pagination.offset, state.pagination.limit)
      this.fetchDocuments()
    }
  }

  updateOptions() {
    const { t } = this.props

    categoriesOptions = []

    documentStatusOptions = [
      { value: DOCUMENT_STATUS_SAVED, label: t('user.document.status_' + DOCUMENT_STATUS_SAVED), type: 'document_status' },
      { value: DOCUMENT_STATUS_SIGNED_PARTIALLY, label: t('user.document.status_' + DOCUMENT_STATUS_SIGNED_PARTIALLY), type: 'document_status' },
      { value: DOCUMENT_STATUS_SIGNED, label: t('user.document.status_' + DOCUMENT_STATUS_SIGNED), type: 'document_status' },
    ]

    this.state.documentCategories.forEach(category => (
      categoriesOptions.push({ value: category.id, label: category.name, type: 'document_category' })
    ))

    return [
      {
        label: t('user.document.document_statuses'),
        options: documentStatusOptions
      },
      {
        label: t('user.settings.document_categories'),
        options: categoriesOptions
      }
    ]
  }

  parseQueryString() {
    const search = this.props.location.search || ''
    const match = search.match(/page=(\d)?/)

    return {
      page: match ? parseInt(match[1], 10) : undefined,
    }
  }

  getOffset(page) {
    return (page - 1) * this.state.pagination.limit
  }

  getMatchCategory() {
    return this.props.match.params.category || DOCUMENT_CAT_ALL
  }

  getTabs() {
    const { user: { subscription, permissions } } = this.props
    const category = this.getMatchCategory()

    const tabs = [
      {
        id: 1,
        active: category === DOCUMENT_CAT_ALL,
        title: 'user.document.all_documents',
        number: undefined,
        category: DOCUMENT_CAT_ALL,
      },
      {
        id: 2,
        active: category === DOCUMENT_CAT_MINE,
        title: 'user.document.my_documents',
        number: undefined,
        category: DOCUMENT_CAT_MINE,
      },
      {
        id: 3,
        active: category === DOCUMENT_CAT_WAITING_ME,
        title: 'user.document.waiting_me_to_sign',
        number: undefined,
        category: DOCUMENT_CAT_WAITING_ME,
      },
      {
        id: 4,
        active: category === DOCUMENT_CAT_WAITING_OTHERS,
        title: 'user.document.waiting_others_to_sign',
        number: undefined,
        category: DOCUMENT_CAT_WAITING_OTHERS,
      },
    ]

    if (permissions.isAdmin && (subscription === 'teams' || subscription === 'flexible' || subscription === 'limited_flexible')) {
      tabs.push(
        {
          id: 5,
          active: category === DOCUMENT_CAT_ALL_COMPANY,
          title: 'user.document.all_company_documents',
          number: undefined,
          category: DOCUMENT_CAT_ALL_COMPANY,
        },
      )
    }

    return tabs
  }

  getActiveTab(tabs) {
    tabs = tabs || this.state.tabs

    return tabs.find(t => t.active)
  }

  fetchDocuments() {
    const { pagination, searchValue, documentStatus, documentCategory } = this.state
    const category = this.getActiveTab().category

    if ((!category) in DOCUMENT_CATS) {
      this.setDocuments({ data: [], totalCount: undefined })

      return
    }

    if (!this.state.documentsLoading) {
      this.setState({ documentsLoading: true })
    }

    documentService
      .getList(category, pagination.offset, pagination.limit, searchValue, documentStatus, documentCategory)
      .then(data => this.setDocuments(data))
      .then(() => {
        this.documentSelectEffect()
        this.updateSelectedDocumentHistory()
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchNotViewedDocumentsCount() {
    documentService
      .getNotViewedDocumentsCounts()
      .then(data => this.setState({ notViewedDocuments: data.notViewedDocumentsCount }))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setDocuments(data) {
    const { data: documents, totalCount } = data

    this.transformFetchedDocuments(documents)
    this.setState(prevState => ({
      documents,
      documentsLoading: false,
      pagination: {
        ...prevState.pagination,
        total: totalCount,
      },
    }))

    this.handleDocumentSelectHistory(documents)
  }

  transformFetchedDocuments(documents) {
    documents.forEach(doc => {
      doc.selected = false
    })
  }

  onDocumentsTabClick(tabIndex) {
    const { match, history, saveDocumentSelectAllHistoryInfo, saveDocumentSelectedDocumentsHistoryInfo, saveSelectedCanDeleteSelectedDocumentsInfo, paginationHistory } = this.props
    const tabs = this.state.tabs.map(t => Object.assign({}, t))

    tabs.forEach(tab => tab.active = false)
    tabs[tabIndex].active = true
    saveDocumentSelectAllHistoryInfo(false)
    saveSelectedCanDeleteSelectedDocumentsInfo([])
    saveDocumentSelectedDocumentsHistoryInfo([])

    this.setState({
      tabs,
      pagination: {
        page: 1,
        offset: this.getOffset(1),
        limit: paginationHistory.pageSize,
        total: undefined,
      }
    })

    const activeTab = tabs.find(tab => tab.active === true)

    this.props.saveDocumentTabsHistoryInfo(activeTab.id)

    const newPath = match.path.indexOf(':category') !== -1
      ? match.path.replace(/:category/, tabs[tabIndex].category)
      : `${match.path}/${tabs[tabIndex].category}`

    history.push(newPath)
  }

  onPaginationNextClick() {
    this.setPaginationPage(this.state.pagination.page + 1)
  }

  onPaginationPreviousClick() {
    this.setPaginationPage(this.state.pagination.page - 1)
  }

  setPaginationPage(page) {
    this.props.history.push({
      search: `?page=${page}`,
    })

    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
        offset: this.getOffset(page),
      },
      selectAllChecked: false
    }))

    this.props.saveDocumentSelectAllHistoryInfo(false)
  }

  updateSelectedDocumentHistory() {
    const { documents } = this.state
    let existingCanDeleteDocumentIds = this.props.selectedCanDeleteDocumentsHistory

    if (this.state.selectAllChecked) {
      const selectedDocumentIds = documents.map(doc => doc.uuid)
      const existingDocumentIds = [...new Set([...selectedDocumentIds, ...this.props.selectedDocumentHistory])]

      documents.forEach(document => {
        if (document.permissions.canDelete) {
          existingCanDeleteDocumentIds = [...new Set([document.uuid, ...existingCanDeleteDocumentIds])]
        }
      })

      this.props.saveSelectedCanDeleteSelectedDocumentsInfo(existingCanDeleteDocumentIds)
      this.props.saveDocumentSelectedDocumentsHistoryInfo(existingDocumentIds)
    }
  }

  onDocumentSelect(index) {
    const { documents } = this.state
    documents[index].selected = !documents[index].selected

    this.setState({ documents })
    this.resetSelectedAllDocuments()
    this.documentSelectEffect()

    let existingDocumentIds = this.props.selectedDocumentHistory
    let existingCanDeleteDocumentIds = this.props.selectedCanDeleteDocumentsHistory
    if (documents[index].selected) {
      if (documents[index].permissions.canDelete) {
        existingCanDeleteDocumentIds = [...new Set([documents[index].uuid, ...this.props.selectedCanDeleteDocumentsHistory])]
      }

      existingDocumentIds = [...new Set([documents[index].uuid, ...this.props.selectedDocumentHistory])]
    } else {
      existingCanDeleteDocumentIds.splice(existingCanDeleteDocumentIds.indexOf(documents[index].uuid), 1)
      existingDocumentIds.splice(existingDocumentIds.indexOf(documents[index].uuid), 1)
    }

    this.props.saveSelectedCanDeleteSelectedDocumentsInfo(existingCanDeleteDocumentIds)
    this.props.saveDocumentSelectedDocumentsHistoryInfo(existingDocumentIds)
  }

  documentSelectEffect() {
    const { documents, user } = this.state
    let selectedCount = 0
    let totalCanBeSigned = 0
    let totalCanGenerateLink = 0

    documents.forEach(d => {
      selectedCount += d.selected
      if (d.selected) {
        if (d.permissions.canSign) {
          if (d.format === DOCUMENT_FORMAT_PDF) {
            totalCanBeSigned++
          }
        }

        if (d.status !== 'signed' && d.isOwner===true) {
          totalCanGenerateLink++
        }
      }
    })

    const signValidation = this.resolveCanSign(selectedCount, totalCanBeSigned)

    this.setState({
      selectAllChecked: documents.length > 0 && selectedCount === documents.length,
      isSignaturesCountOk: signValidation.isSignaturesCountOk,
      selectedAllCanBeSigned: selectedCount === totalCanBeSigned,
      canSign: signValidation.canSign,
      canGenerateLink: totalCanGenerateLink > 0 && user.subscription === 'flexible',
    })
  }

  resolveCanSign(selectedCount, totalCanBeSigned) {
    const subscriptionPayType = this.state.user.subscriptionPayType
    const totalSignatures = this.state.user.subscriptionSignaturesLeft + this.state.user.boughtSignatures
    let isSignaturesCountOk = true
    let canSign = selectedCount !== 0 && totalCanBeSigned !== 0

    if (canSign) {
      if (subscriptionPayType === 'postpaid') {
        canSign = true
      }

      if (subscriptionPayType === 'prepaid') {
        isSignaturesCountOk = totalSignatures >= selectedCount
        canSign = isSignaturesCountOk
      }
    }

    return {
      isSignaturesCountOk: isSignaturesCountOk,
      canSign: canSign,
    }
  }

  onDocumentDownloadClick(index) {
    const { documents } = this.state
    const url = `/api/v1/${getUserWorkspace()}/document/${documents[index].uuid}/download`

    window.open(url, '_blank')
  }

  onDocumentsDownloadClick() {
    let url = `/api/v1/${getUserWorkspace()}/documents/download?`
    this.props.selectedDocumentHistory.forEach(id => url = url + 'documentsIds[]=' + id + '&')
    window.open(url, '_blank')
  }

  onDocumentsDeleteClick() {
    this.setState({
      modalRemove: {
        active: true,
      },
    })
  }

  onModalRemoveDocumentsClick() {
    const { t, selectedCanDeleteDocumentsHistory, showStatusMessage } = this.props
    if (selectedCanDeleteDocumentsHistory.length === 0) {
      showStatusMessage(SM_TYPE_ERROR, t('user.document.selected_documents_cannot_be_removed'))
    } else {
      return documentService
        .removeSelected(selectedCanDeleteDocumentsHistory)
        .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
        .finally(() => {
          this.setState({ modalRemove: { active: false } })
          this.fetchDocuments()
          this.resetSelectedAllDocuments()
          this.resetControlButtons()

          showStatusMessage(SM_TYPE_SUCCESS, t('user.document.selected_documents_were_successfully_removed'))
        })
    }
  }

  onDocumentsMigrateClick() {
    this.setState({
      modalMigrateDocument: {
        active: true
      }
    })
  }

  onModalMigrateDocumentsClick([businessId]) {
    const { t } = this.props

    return documentService
      .migrateSelected(businessId, { documentsIds: this.props.selectedDocumentHistory })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalMigrateDocument: { active: false } })
        this.selectDeselectAll(false)
        this.resetControlButtons()
        this.fetchDocuments()
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.selected_documents_were_successfully_migrated'))
      })
  }

  onModalMigrateDocumentsToWorkspaceClick(workspaceId, isBusiness) {
    const { t } = this.props
    const { documents } = this.state
    const documentsIds = []

    documents.forEach(doc => doc.selected ? documentsIds.push(doc.uuid) : null)

    return documentService
      .migrateSelectedDocumentsToWorkspace(documentsIds, workspaceId, isBusiness)
      .then(() => {
        this.selectDeselectAll(false)
        this.resetControlButtons()
        this.fetchDocuments()
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_migrated'))
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ modalMigrateDocument: { active: false } }))
  }

  onDocumentShareClick(index) {
    const document = this.state.documents[index]

    this.setState({
      modalShare: {
        active: true,
        document: document,
      },
    })
  }

  hideModalShare() {
    this.setState({ modalShare: { active: false } })
  }

  inviteSigner([name, surname, email, purpose]) {
    const { t } = this.props
    const { document } = this.state.modalShare

    documentService
      .inviteSigner(document.uuid, { name, surname, email, purpose })
      .then(() => {
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_signer_was_invited'))
        this.fetchDocuments()
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.hideModalShare())
  }

  inviteSigners(signers) {
    const { t, showStatusMessage } = this.props
    const { document } = this.state.modalShare

    documentService
      .inviteSigners(document.uuid, { signers: signers })
      .then(() => {
        showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_signer_was_invited'))
        this.fetchDocuments()
      })
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.hideModalShare())
  }

  onDocumentDeleteClick(index) {
    this.setState({
      modalRemove: {
        active: true,
        documentIndex: index,
      },
    })
  }

  onModalRemoveDocumentClick() {
    const { t } = this.props
    const { documents, modalRemove } = this.state
    const document = documents[modalRemove.documentIndex]

    documentService
      .remove(document.uuid)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalRemove: { active: false } })
        this.fetchDocuments()
      })
  }

  onSelectAllChange() {
    const { documents, selectAllChecked } = this.state
    const selectedDocumentIds = []
    let existingDocumentIds = this.props.selectedDocumentHistory
    let existingCanDeleteDocumentIds = this.props.selectedCanDeleteDocumentsHistory

    this.selectDeselectAll(!selectAllChecked)

    if (!selectAllChecked) {
      documents.forEach(doc => {
        if (doc.permissions.canDelete) {
          existingCanDeleteDocumentIds = [...new Set([doc.uuid, ...existingCanDeleteDocumentIds])]
        }
        selectedDocumentIds.push(doc.uuid)
      })
      existingDocumentIds = [...new Set([...selectedDocumentIds, ...existingDocumentIds])]
    } else {
      documents.forEach(doc => {
        existingCanDeleteDocumentIds.splice(existingCanDeleteDocumentIds.indexOf(doc.uuid), 1)
        existingDocumentIds.splice(existingDocumentIds.indexOf(doc.uuid), 1)
      })
    }

    this.props.saveSelectedCanDeleteSelectedDocumentsInfo(existingCanDeleteDocumentIds)
    this.props.saveDocumentSelectedDocumentsHistoryInfo(existingDocumentIds)
    this.props.saveDocumentSelectAllHistoryInfo(!selectAllChecked)
  }

  selectDeselectAll(select) {
    const { documents } = this.state
    documents.forEach(d => d.selected = select)
    this.setState({ documents })
    this.documentSelectEffect()
  }

  onSearchChange(value) {
    this.props.saveDocumentSearchFieldInfo(value)
    this.setState({ searchValue: value, pagination: { page: 1, offset: this.getOffset(1), limit: this.props.paginationHistory.pageSize } })
    this.setPaginationPage(this.state.pagination.page)
    this.resetSelectedAllDocuments()
    this.fetchDocuments()
  }

  isContentShrink() {
    if (document.querySelector('#sidebar').className === 'shrink') {
      document.querySelector('main').classList.toggle('shrink')
    }
  }

  onFiltersSelectChange(options, categoryOptions) {
    const currentData = []
    const selectedCategory = []
    let selectedStatus = undefined

    this.props.saveDocumentFilterHistoryInfo(options)

    if (options && options.length > 0) {
      options.map(option => {
        currentData.push({ id: option.value, name: option.label, type: option.type })
        if (option.type === 'document_category') {
          selectedCategory.push({ id: option.value, name: option.label, type: 'document_category' })
        }

        if (option.type === 'document_status') {
          selectedStatus = option.value
        }
      })

      this.setState({
        documentStatus: selectedStatus,
        documentCategory: selectedCategory,
      })

      if (options.length === 1) {
        options.forEach(
          option => option.type === 'document_status'
            ? this.setState({ documentStatus: option.value, documentCategory: undefined })
            : this.setState({ documentCategory: option.value, documentStatus: undefined })
        )
      }
    } else {
      this.setState({ documentStatus: undefined, documentCategory: undefined })
    }

    this.resetSelectedAllDocuments()
    this.setState({ selectedFilter: currentData })
    documentStatusOptions.forEach(statusOption => statusOption.disabled = false)
    categoryOptions.forEach(categoryOption => categoryOption.disabled = false)
  }

  showSelectedValue() {
    const selectedCat = []

    if (this.state.selectedFilter) {
      this.state.selectedFilter.map((item) => {
        selectedCat.push({ label: item.name, value: item.id, type: item.type })
      })
    }

    return selectedCat
  }

  onUploadSuccess(documentUuid, callback) {
    const { history } = this.props
    const uuids = typeof documentUuid === 'string' ? [documentUuid] : documentUuid

    uuids.length === 1
      ? history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW, { documentId: uuids[0] }))
      : this.fetchDocuments()

    typeof callback === 'function' && callback()
  }

  resetControlButtons() {
    this.selectDeselectAll(false)
    this.props.saveSelectedCanDeleteSelectedDocumentsInfo([])
    this.props.saveDocumentSelectedDocumentsHistoryInfo([])
  }

  componentDidMount() {
    const { saveDocumentTabsHistoryInfo } = this.props
    const category = this.getMatchCategory()

    this.setTabListHistory()
    this.fetchDocuments(category)
    saveDocumentTabsHistoryInfo(this.getActiveTab().id)

    this.isContentShrink()
  }

  setTabListHistory() {
    const { tabs } = this.state
    const { tabListHistory, history, match } = this.props

    if (tabListHistory !== null) {
      const findTabToSetActive = tabs.find(t => t.id === tabListHistory)

      tabs.forEach(tab => tab.active = false)
      findTabToSetActive.active = true

      history.push(match.path.replace(/:category/, tabs.find(t => t.id === tabListHistory).category))
    }
  }

  setDocumentFilterHistory() {
    const { documentFilterHistory } = this.props
    const currentData = []
    const documentCategory = []
    let documentStatus = undefined

    if (documentFilterHistory === null) {
      return Promise.resolve()
    }

    if (documentFilterHistory && documentFilterHistory.length > 0) {
      documentFilterHistory.map(option => {
        currentData.push({ id: option.value, name: option.label, type: option.type })
        if (option.type === 'document_category') {
          documentCategory.push({ id: option.value, name: option.label, type: 'document_category' })
        }

        if (option.type === 'document_status') {
          documentStatus = option.value
        }
      })
    }

    this.resetSelectedAllDocuments()

    return {
      documentCategory,
      documentStatus,
      selectedFilter: currentData,
    }
  }

  acceptDiscount() {
    let discountCode
    const { t, showStatusMessage } = this.props

    if (this.state.discountFirst) {
      discountCode = 'BACK25'
    }

    if (this.state.discountSecond) {
      discountCode = 'BACK40'
    }

    return apiClient
      .post(`v1/${getUserWorkspace()}/discount`, {
        discountCode: discountCode
      })
      .then(() => {
        showStatusMessage(SM_TYPE_SUCCESS, t('user.pricing.discount_was_successfully_took_and_plan_continued'))
        this.setState({ modalFutureDiscount: { active: false } })
      })
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.props.fetchUser()
        this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS))
      })
  }

  onModalFutureDiscountCloseClick() {
    this.setState({ modalFutureDiscount: { active: false } })
    this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS))
  }

  handleDocumentSelectHistory(documents) {
    const { selectAllHistory, selectedDocumentHistory } = this.props
    const { documents: stateDocuments } = this.state

    if (documents && selectAllHistory) {
      documents.forEach(doc => doc.selected = selectAllHistory)
    }

    if (documents && selectedDocumentHistory.length > 0) {
      selectedDocumentHistory.forEach(docUuid => {
        const found = stateDocuments.find(d => d.uuid === docUuid)
        if (found) { found.selected = true }
      })
    }

    this.setState({
      documents: stateDocuments
    })
  }

  resetSelectedAllDocuments() {
    this.setState({
      selectAllChecked: false,
    })

    this.props.saveDocumentSelectAllHistoryInfo(false)
  }

  onDocumentsSign() {
    const { documents, selectedAllCanBeSigned } = this.state
    const selectedDocuments = documents.filter(d => d.selected)

    this.props.history.push({
      pathname: generatePath(ROUTE_NAMES.USER_USB_DOCUMENTS),
      state: { selectedDocuments, selectedAllCanBeSigned }
    })
  }

  onPageSizeChange({ value }) {
    let newOffset = this.state.pagination.offset
    const newPage = Math.ceil((this.state.pagination.offset + 1) / value)

    if (this.state.pagination.page !== 1 && newPage !== this.state.pagination.page) {
      newOffset = (newPage - 1) * value
      this.props.history.push({
        search: `?page=${newPage}`,
      })
    }

    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        limit: value,
        offset: newOffset,
        page: newPage
      }
    }))
  }

  onGenerateSigningLink() {
    const { langCode } = this.props

    documentService
      /* eslint-disable camelcase */
      .getTemporarySigningLinkV2({
        documents: this.props.selectedDocumentHistory,
        from_platform: true,
        expire_after: 20160,
        language: langCode,
      })
      /* eslint-enable camelcase */
      .then((data) => {
        this.setState({ signingLink: data.temporary_signing_link, expireAfter: data.valid_until, modalGenerateLinkActive: true })
      })
      .catch(error => {
        this.setState({ signingLinkError: error.message })
      })
  }

  render() {
    const { t, user, selectedDocumentHistory } = this.props
    const { notViewedDocuments, documents } = this.state
    const dropUpOptions = [
      {
        id: 1,
        name: 'generate_sign_link',
        onClick: this.onGenerateSigningLink,
        btnDisabled: !this.state.canGenerateLink,
        isFlexibleAccount: user.subscription === 'flexible'
      },
    ]
    const isSearchDirty = this.state.searchValue
      || this.state.documentStatus
      || this.state.documentCategory
      || this.state.pagination.page !== 1

    return (
      <main>
        <AppHeaderDocuments
          t={t}
          searchValue={this.state.searchValue}
          onSearchChange={this.onSearchChange}
        />
        <section className="tabs--documents" id="tabs">
          <TabsList wrapperClassName="grid grid--flex" withNumbers={true} showOverlay={true}>
            {this.state.tabs.map((tab, index) => (
              <Tab
                key={tab.id}
                active={tab.active}
                title={t(tab.title)}
                number={typeof notViewedDocuments[tab.category] !== 'undefined' ? notViewedDocuments[tab.category] : 0}
                onClick={this.onDocumentsTabClick.bind(this, index)}
              />
            ))}
          </TabsList>
        </section>

        <section id="document-list-filter">
          <div className="grid grid--flex">
            <div className="document-list__select-all">
              {!this.state.documentsLoading && documents.length !== 0 ?
                <div className="form-group__checkbox" style={{ display: 'inline', float: 'left', paddingTop: '10px' }}>
                  <input
                    type="checkbox"
                    id="document-all"
                    onChange={this.onSelectAllChange}
                    checked={this.state.selectAllChecked}
                  />
                  <label htmlFor="document-all">{t('common.select_all')}</label>
                </div>
                : <div className="invisible-checkbox-spacer" />
              }
              <div className="document-list__select-filters">
                <Select
                  name="documentStatus"
                  components={animatedComponents}
                  isMulti
                  options={this.updateOptions()}
                  placeholder={t('common.filter_by')}
                  onChange={options => this.onFiltersSelectChange(options, categoriesOptions)}
                  value={this.showSelectedValue()}
                  isOptionDisabled={(option) => option.disabled}
                />
              </div>
              <ControlButtonsDocument
                t={t}
                active={selectedDocumentHistory.length > 0}
                className={selectedDocumentHistory.length > 0 ? 'show' : ''}
                showMigrate={true}
                showRemove={true}
                showDownload={true}
                onDownloadClick={() => { this.onDocumentsDownloadClick() }}
                onRemoveClick={() => { this.onDocumentsDeleteClick() }}
                onMigrateClick={() => { this.onDocumentsMigrateClick() }}
                showShare={false}
              />
            </div>
          </div>
        </section>

        {this.state.documentsLoading && (
          <section id="empty-list">
            <div className="grid grid--flex">
              <div className="empty__content">
                <Loader loadingText={t('common.loading')} />
              </div>
            </div>
          </section>
        )}

        {!this.state.documentsLoading && documents.length === 0 && isSearchDirty && (
          <section id="empty-list">
            <div className="grid grid--flex">
              <div className="empty__content">
                <img src={emptySearchImg} alt={t('user.document.no_matching_document_were_found')} />
                <h3 className="empty__title">{t('user.document.no_matching_document_were_found')}</h3>
              </div>
            </div>
          </section>
        )}

        {!this.state.documentsLoading && documents.length === 0 && !isSearchDirty && (
          <DropzoneComponent
            user={this.props.user}
            showStatusMessage={this.props.showStatusMessage}
            onUploadSuccess={this.onUploadSuccess}
            uploadType={DOCUMENT_UPLOAD_TYPE_UPLOAD}
            documentsList={true}
            multipleFiles={true}
            maxFileSizeLimit={this.props.user.maxFileSizeLimit}
          />
        )}

        {!this.state.documentsLoading && documents.length > 0 && (
          <section id="document-list">
            <div className="grid grid--flex">
              <DocumentsList>
                {documents.map((doc, index) => (
                  <Document
                    t={t}
                    key={doc.uuid}
                    searchValue={this.state.searchValue}
                    uuid={doc.uuid}
                    type={doc.format}
                    title={doc.displayTitle}
                    categories={doc.categories}
                    selected={doc.selected}
                    uploadDate={doc.uploadDate}
                    signedCount={doc.signedCount}
                    invitedCount={doc.invitedCount}
                    showRemove={doc.permissions.canDelete}
                    showCategories={doc.permissions.canAssignCategory}
                    notViewed={doc.notViewed}
                    status={doc.status}
                    category={this.getActiveTab().category}
                    onSelect={this.onDocumentSelect.bind(this, index)}
                    onDownloadClick={this.onDocumentDownloadClick.bind(this, index)}
                    onShareClick={this.onDocumentShareClick.bind(this, index)}
                    onDeleteClick={this.onDocumentDeleteClick.bind(this, index)}
                  />
                ))}
              </DocumentsList>
            </div>
          </section>
        )}
        {!this.state.documentsLoading && (documents.length > 0 || this.state.pagination.page !== 1) && (
          <Pagination
            offset={this.state.pagination.offset}
            limit={this.state.pagination.limit}
            pageTotal={documents.length}
            onNextClick={this.onPaginationNextClick}
            onPreviousClick={this.onPaginationPreviousClick}
            showPageSizeSelect={true}
            onPageSizeChange={this.onPageSizeChange}
          />
        )}
        <div id="bottom-bar" className="bottom-bar">
          <button
            className="btn btn--primary documents-btn-margin-right"
            disabled={user.authMethod !== userService.AUTH_TYPE_SMARTCARD || !this.state.canSign}
            onClick={this.onDocumentsSign}
          >
            {t('start_signing')}
          </button>
          <DropUpList dropUpOptions={dropUpOptions} />
          {user.authMethod === userService.AUTH_TYPE_SMARTCARD && !this.state.isSignaturesCountOk &&
          <InformationBar
            icon={ErrorIcon}
            iconAlt="Warning icon"
            informationClass='warning'
            additionalClass='bottom-mobile-bar'
            informationText={
              <>
                {t('user.document.not_enough_signatures')}
                      &nbsp;
                <Link
                  to={{ pathname: generatePath(ROUTE_NAMES.USER_PRICING) }}
                  target="_blank"
                  className="anchor"
                >
                  {t('user.document.not_enough_signatures_link')}
                </Link>
              </>
            }
          />
          }
        </div>
        <ModalRemove
          active={this.state.modalRemove.active}
          title={this.state.modalRemove.documentIndex !== undefined ?
            t('user.document.remove_document') :
            t('user.document.remove_documents')}
          message={this.state.modalRemove.documentIndex !== undefined ?
            t('user.document.are_you_sure_you_want_to_remove') :
            t('user.document.are_you_sure_you_want_to_remove_selected')}
          onCloseClick={() => this.setState({ modalRemove: { active: false } })}
          onRemoveClick={this.state.modalRemove.documentIndex !== undefined ?
            this.onModalRemoveDocumentClick :
            this.onModalRemoveDocumentsClick}
        />
        {this.state.modalShare.document ?
          <ModalShare
            active={this.state.modalShare.active}
            access={this.state.modalShare.document ? this.state.modalShare.document.access : undefined}
            documentUuid={this.state.modalShare.document ? this.state.modalShare.document.uuid : undefined}
            title={t('share_document')}
            addSigner={this.inviteSigner}
            addSigners={this.inviteSigners}
            onCloseClick={this.hideModalShare}
            showStatusMessage={this.props.showStatusMessage}
          /> : null
        }
        <Modal
          active={false}
          className="popup__locked"
          size="small"
          title={t('document_protected')}
          message={t('enter_password_to_unlock')}
        >
          <div className="ta-center">
            <form>
              <div className="form-group">
                <div className="form-wrap form-wrap--password form-wrap--password--full">
                  <input className="form-group__input" type="password" id="js-input-visible" />
                  <button className="form-btn form-btn--visible" type="button" id="js-btn-visible" />
                </div>
              </div>
              <button className="btn btn--primary" type="submit">{t('submit')}</button>
            </form>
          </div>
        </Modal>
        <ModalDocumentMigrationCompany
          t={t}
          title={t('user.document.migrate_document_to_other_account')}
          onCloseClick={() => this.setState({ modalMigrateDocument: { active: false } })}
          active={this.state.modalMigrateDocument.active}
          migrateDocument={this.onModalMigrateDocumentsClick}
          migrateDocumentToWorkspace={this.onModalMigrateDocumentsToWorkspaceClick}
          companies={this.state.companies}
          user={user}
          allWorkspaces={this.props.allWorkspaces}
        />
        <ModalFutureDiscount
          t={t}
          active={this.state.modalFutureDiscount.active}
          title={t('user.pricing.1_step_left')}
          message={t('user.pricing.activate_discount_and_continue_plan')}
          onCloseClick={this.onModalFutureDiscountCloseClick}
          onAcceptDiscountClick={this.acceptDiscount}
        />

        <ModalGenerateLink
          size="small"
          active={this.state.modalGenerateLinkActive}
          title={t('share_document')}
          onCloseClick={() => this.setState({ modalGenerateLinkActive: false })}
          showStatusMessage={this.props.showStatusMessage}
          signingLink={this.state.signingLink}
          expireAfter={this.state.expireAfter}
          signingLinkError={this.state.signingLinkError}
        />
        <StatusMessages />
      </main>
    )
  }
}

Documents.propTypes = {
  location: PropTypes.object,
  user: PropTypes.object,
  paginationHistory: PropTypes.object,
  showStatusMessage: PropTypes.func,
  saveDocumentSearchFieldInfo: PropTypes.func,
  saveDocumentTabsHistoryInfo: PropTypes.func,
  saveDocumentFilterHistoryInfo: PropTypes.func,
  saveDocumentSelectAllHistoryInfo: PropTypes.func,
  saveDocumentSelectedDocumentsHistoryInfo: PropTypes.func,
  saveDocumentPaginationHistoryInfo: PropTypes.func,
  t: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  fetchUser: PropTypes.func,
  allWorkspaces: PropTypes.array,
  selectedDocumentHistory: PropTypes.array,
  searchFieldHistory: PropTypes.string,
  tabListHistory: PropTypes.number,
  documentFilterHistory: PropTypes.any,
  selectAllHistory: PropTypes.bool,
  saveSelectedCanDeleteSelectedDocumentsInfo: PropTypes.func,
  selectedCanDeleteDocumentsHistory: PropTypes.array,
  langCode: PropTypes.string,
}
