import React from 'react'
import DropzoneComponent from '~/js/components/DropzoneComponent'
import StatusMessages from '~/js/containers/StatusMessages'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import { DOCUMENT_UPLOAD_TYPE_UPLOAD } from '../../../../models/Document'
import PropTypes from 'prop-types'

export default class UploadDocument extends React.Component {
  constructor(props) {
    super(props)
    this.onUploadSuccess = this.onUploadSuccess.bind(this)
  }

  isContentShrink() {
    if (document.querySelector('#sidebar').className === 'shrink') {
      document.querySelector('main').classList.toggle('shrink')
    }
  }

  componentDidMount() {
    this.isContentShrink()
  }

  onUploadSuccess(documentUuid, callback) {
    const { history } = this.props
    const uuids = typeof documentUuid === 'string' ? [documentUuid] : documentUuid

    history.push(uuids.length === 1
      ? generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW, { documentId: uuids[0] })
      : generatePath(ROUTE_NAMES.USER_DOCUMENTS)
    )
    typeof callback === 'function' && callback()
  }

  render() {
    return (
      <div id="page-container">
        <main>
          <DropzoneComponent
            user={this.props.user}
            showStatusMessage={this.props.showStatusMessage}
            onUploadSuccess={this.onUploadSuccess}
            uploadType={DOCUMENT_UPLOAD_TYPE_UPLOAD}
            multipleFiles={true}
            maxFileSizeLimit={this.props.user.maxFileSizeLimit}
          />
        </main>
        <StatusMessages fullWidth={false} />
      </div>
    )
  }
}

UploadDocument.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  showStatusMessage: PropTypes.func,
}