// @flow

import * as React from 'react'
import Select from '../Select'
import { useTranslation } from 'react-i18next'

const pageSizeOptions = [
  { value: 10, label: 10 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
]

type Props = {
  offset: number,
  limit: number,
  onPreviousClick: () => {},
  onNextClick: () => {},
  total: ?number,
  pageTotal: ?number,
  showPageSizeSelect: ?boolean,
  onPageSizeChange: ?() => {},
}

export default function Pagination({
  offset,
  limit,
  onPreviousClick,
  onNextClick,
  total,
  pageTotal,
  onPageSizeChange,
  showPageSizeSelect,
}: Props): React.Node {
  const { t } = useTranslation()
  const firstElement = offset + 1
  const lastElement = offset + limit
  const prevPageExist = offset > 0
  let nextPageExist = true

  if (total !== null && total !== undefined) {
    nextPageExist = lastElement < total
  } else if (pageTotal !== null && pageTotal !== undefined) {
    nextPageExist = pageTotal >= limit
  }

  return (
    <div className="pagination">
      {showPageSizeSelect &&
        <>
          <span className="page-size-label">{t('common.page_size')}</span>
          <Select
            className="page-size-filters"
            menuPlacement="top"
            name="pageSize"
            onChange={onPageSizeChange}
            options={pageSizeOptions}
            placeholder={limit}
          />
        </>
      }
      {total !== null && total !== undefined && (
        <div className="pagination__number">
          <span className="pagination__from">{firstElement} - {total < lastElement ? total : lastElement}</span>
          {t('common.pagination_of')}
          <span className="pagination__to">{total}</span>
        </div>
      )}
      <div className="pagination__buttons">
        <button className="btn prev" type="button" onClick={onPreviousClick} disabled={!prevPageExist} />
        <button className="btn next" type="button" onClick={onNextClick} disabled={!nextPageExist} />
      </div>
    </div>
  )
}